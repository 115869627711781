import { action, makeObservable, observable, toJS } from "mobx";
import uiStore from "./UiStore";
import {
    branchService,
    companyGroupsService,
    locationsService,
    brandsService,
    userService,
    integrationService,
} from "../services";
import { Branch, Location, Brand } from "../models";
import { setLinkedById, unsetLinkedById } from "../helper";
import CommonPageStore from "stores/CommonPageStore";

/**
 * Branches store
 *
 * @author Roman Zaycev <roman.zaycev@olmisoft.com>
 */
class BranchStore extends CommonPageStore {
    /**
     * @type {Array<Branch>}
     */
    items = [];

    // noinspection JSValidateTypes
    /**
     * @type {Branch}
     */
    entity = Branch.getEmptyEntity().getPlainObject();

    /**
     * @type {{companyGroups: CompanyGroup[]|undefined, location: Location|undefined, brand: Brand|undefined}}
     */
    entityAdditional = {
        location: new Location(),
        brand: new Brand(),
        companyGroups: [],
    };

    /**
     * @type {BranchManagerLink[]}
     */
    users = [];

    /**
     * @type {{itemCount: number}}
     */
    usersPager = {};

    /**
     * @type {int[]}
     */
    usersUnsavedLinks = [];

    /**
     * @type {int[]}
     */
    usersUnsavedDelLinks = [];

    /**
     * @type {BranchAdPlatformLink[]}
     */
    integrations = [];

    /**
     * @type {{itemCount: number}}
     */
    integrationsPager = {};

    /**
     * @type {int[]}
     */
    integrationsUnsavedLinks = [];

    /**
     * @type {int[]}
     */
    integrationsUnsavedDelLinks = [];

    /**
     * @type {Array<CompanyGroup>}
     */
    lazyLoadCompanyGroups = [];

    /**
     * @type {Array<Location>}
     */
    lazyLoadLocations = [];

    /**
     * @type {Array<Brand>}
     */
    lazyLoadBrands = [];

    clientId = -1;

    constructor() {
        super();

        makeObservable(
            this,
            {
                items: observable,
                entity: observable,
                entityAdditional: observable,

                clientId: observable,

                users: observable,
                usersPager: observable,
                integrations: observable,

                lazyLoadCompanyGroups: observable,
                lazyLoadLocations: observable,
                lazyLoadBrands: observable,

                setItems: action,
                setEntity: action,
                saveEntity: action,
                setEntityAdditional: action,
                resetEntity: action,
                addEntity: action,

                setClientId: action,
                setUsers: action,
                setUsersPager: action,
                setIntegrations: action,
                setIntegrationsPager: action,

                setLazyLoadCompanyGroups: action,
                setLazyLoadLocations: action,
                setLazyLoadBrands: action,

                setUserLinked: action,
                delUserLinked: action,

                setIntegrationLinked: action,
                delIntegrationLinked: action,
            }
        );
    }


    /**
     * @param {Array<Branch>} items
     */
    setItems = (items) => {
        this.items = items || [];
    }

    setClientId = (clientIdFilter) => {
        this.clientId = clientIdFilter;
    }


    /**
     * @param {{}} entity
     */
    setEntity = (entity = {}) => {
        this.entity = {
            ...this.entity,
            ...entity,
        }
    }

    getAllBranches = () => {
        let filter= {
            page: toJS(this.pager),
            title: toJS(this.title),
            sort: toJS(this.sort),
        }

        if (this.fEnabled !== -1) {
            filter = {
                ...filter,
                ...{ enabled: toJS(this.fEnabled) }
            }
        }

        if (this.clientId !== -1) {
            filter = {
                ...filter,
                ...{ clientId: toJS(this.clientId) }
            }
        }

        return branchService
            .getAllBranches(filter)
            .then((result) => {
                this.setItems(result?.items);
                this.setSort(result?.sort);
                this.setPager(result?.pager);
            })
            .catch((error) => {
                uiStore.setMessage({
                    severity: 'error',
                    summary: error.response?.data.error.type,
                    detail: error.response?.data.error.description,
                });
            })
    }

    /**
     * @param {int} clientId
     */
    loadItems = (clientId) => {
        let filter = {
            page: toJS(this.pager),
            sort: toJS(this.sort),
            title: toJS(this.title),
        };



        if (this.fEnabled !== -1) {
            filter = {
                ...filter,
                ...{ enabled: toJS(this.fEnabled) }
            }
        }

        uiStore.startLoading();
        branchService
            .getBranches(clientId, filter)
            .then((result) => {
                this.setItems(result?.items);
                this.setSort(result?.sort);
                this.setPager(result?.pager);
            })
            .catch((error) => {
                uiStore.setMessage({
                    severity: 'error',
                    summary: error.response?.data.error.type,
                    detail: error.response?.data.error.description,
                });
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    /**
     * @param {int} branchId
     * @param {boolean} isEditPage
     */
    loadEntity = (branchId, isEditPage = false) => {
        uiStore.startLoading();

        this.usersUnsavedLinks = [];
        this.usersUnsavedDelLinks = [];

        this.integrationsUnsavedLinks = [];
        this.integrationsUnsavedDelLinks = [];

        this.setIntegrations([]);
        this.setUsers([]);

        branchService
            .getBranchDetailView(branchId, isEditPage ? null : true)
            .then((result) => {
                this.setEntity(result.branch.getPlainObject());

                this.setUsers(result.users.items);
                this.setUsersPager(result.users.pager);

                this.setIntegrations(result.adplatforms.items);
                this.setIntegrationsPager(result.adplatforms.pager);

                this.setEntityAdditional({
                    location: result.entityAdditional.location,
                    brand: result.entityAdditional.brand,
                    companyGroups: result.entityAdditional.companyGroups,
                });
                this.setLazyLoadCompanyGroups(
                    result.entityAdditional.companyGroups.map(cg => cg.getObjectFromApi())
                );
                this.setLazyLoadLocations([
                    result.entityAdditional.location.getObjectFromApi(),
                ]);
                this.setLazyLoadBrands([
                    result.entityAdditional.brand.getObjectFromApi(),
                ]);
            })
            .catch((error) => {
                uiStore.setMessage({
                    severity: 'error',
                    summary: error.response?.data.error.type,
                    detail: error.response?.data.error.description,
                });
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    addEntity = () => {
        uiStore.startLoading();

        return branchService
            .addEntity(this.prepareEntityData())
            .then(result => {
                if (result) {
                    this.setEntity(result.getPlainObject());
                } else {
                    return Promise.reject();
                }
            })
            .catch(error => {
                error.error.data.map(item => {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: error.error.description,
                            detail: item.message,
                        }
                    )
                });

                return Promise.reject();
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    resetEntity = () => {
        this.setEntity(Branch.getEmptyEntity().getPlainObject());

        this.entityAdditional = {
            location: new Location(),
            brand: new Brand(),
            companyGroups: [],
        };

        this.usersUnsavedLinks = [];
        this.usersUnsavedDelLinks = [];

        this.integrationsUnsavedLinks = [];
        this.integrationsUnsavedDelLinks = [];
    }

    /**
     * @param {BranchManagerLink[]} users
     */
    setUsers = (users) => {
        this.users = users;
    }

    setUsersPager = (pager) => {
        this.usersPager = pager;
    }

    /**
     * @param {BranchAdPlatformLink[]} integrations
     */
    setIntegrations = (integrations) => {
        this.integrations = integrations;
    }

    setIntegrationsPager = (pager) => {
        this.integrationsPager = pager;
    }

    setEntityAdditional = (entityAdditional) => {
        this.entityAdditional = entityAdditional;
    }

    setLazyLoadCompanyGroups = (groups) => {
        this.lazyLoadCompanyGroups = groups;
    }

    setLazyLoadLocations = (locations) => {
        this.lazyLoadLocations = locations;
    }

    setLazyLoadBrands = (brands) => {
        this.lazyLoadBrands = brands;
    }

    setAllClientUsersLinks = (userIds) => {
        this.allClientUsersLinks = userIds;
    }

    setUserLinked = (userId) => {
        this.usersUnsavedDelLinks = setLinkedById(userId, this.usersUnsavedLinks, this.usersUnsavedDelLinks);
    }

    delUserLinked = (userId) => {
        this.usersUnsavedLinks = unsetLinkedById(userId, this.usersUnsavedLinks, this.usersUnsavedDelLinks);
    }

    setIntegrationLinked = (integrationId) => {
        this.integrationsUnsavedDelLinks = setLinkedById(
            integrationId,
            this.integrationsUnsavedLinks,
            this.integrationsUnsavedDelLinks,
        );
    }

    delIntegrationLinked = (integrationId) => {
        this.integrationsUnsavedLinks = unsetLinkedById(
            integrationId,
            this.integrationsUnsavedLinks,
            this.integrationsUnsavedDelLinks,
        );
    }

    /**
     * @returns {Promise}
     */
    saveEntity = () => {
        uiStore.startLoading();

        const usersLink = [];
        const usersUnlink = [];

        const integrationsLink = [];
        const integrationsUnlink = [];

        this.usersUnsavedLinks.forEach((userId) => {
            if (!this.userLinkExists(userId)) {
                usersLink.push(userId);
            }
        });
        this.usersUnsavedDelLinks.forEach((userId) => {
            if (this.userLinkExists(userId)) {
                usersUnlink.push(userId);
            }
        });

        this.integrationsUnsavedLinks.forEach((integrationId) => {
            if (!this.integrationLinkExists(integrationId)) {
                integrationsLink.push(integrationId);
            }
        });
        this.integrationsUnsavedDelLinks.forEach((integrationId) => {
            if (this.integrationLinkExists(integrationId)) {
                integrationsUnlink.push(integrationId);
            }
        });

        const entityId = this.entity.id;

        return branchService
            .saveBranch(entityId, this.prepareEntityData())
            .then(() => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Изменения сохранены',
                        sticky: false,
                    }
                );

                const linkPromises = [
                    ...usersLink.map(userId => userService.setLinked(userId, entityId)),
                    ...usersUnlink.map(userId => userService.delLinked(userId, entityId)),
                    ...integrationsLink.map(integrationId => integrationService.setLinked(integrationId, entityId)),
                    ...integrationsUnlink.map(integrationId => integrationService.delLinked(integrationId, entityId)),
                ];

                this.usersUnsavedLinks = [];
                this.usersUnsavedDelLinks = [];

                this.integrationsUnsavedLinks = [];
                this.integrationsUnsavedDelLinks = [];

                if (linkPromises.length) {
                    return Promise.all(linkPromises);
                }

                return Promise.resolve();
            })
            .catch((error) => {
                error.error.data.map(item => {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: error.error.description,
                            detail: item.message,
                        }
                    )
                });

                return Promise.reject();
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    /**
     * @param {string} searchString
     * @param {int} size
     */
    loadLazyCompanyGroups = (searchString, size = 10) => {
        companyGroupsService
            .loadItems({
                clientId: this.entity.clientId,
                title: searchString.toString().trim(),
                pager: {
                    size,
                },
            })
            .then((result) => {
                const items = result.items.map(cg => cg.getObjectFromApi());

                if (items.length) {
                    this.setLazyLoadCompanyGroups(items)
                } else {
                    this.setLazyLoadCompanyGroups([{}]); // Если так не сделать, то ломается фильтр в дропдауне
                }
            });
    }

    /**
     * @param {string} searchString
     * @param {int} size
     */
    loadLazyLocations = (searchString, size = 10) => {
        locationsService
            .loadItems({
                clientId: this.entity.clientId,
                title: searchString.toString().trim(),
                pager: {
                    size,
                },
            })
            .then((result) => {
                const items = result.items.map(cg => cg.getObjectFromApi());

                if (items.length) {
                    this.setLazyLoadLocations(items);
                } else {
                    this.setLazyLoadLocations([{}]);
                }
            });
    }

    /**
     * @param {string} searchString
     * @param {int} size
     */
    loadLazyBrands = (searchString, size = 10) => {
        brandsService
            .loadItems({
                clientId: this.entity.clientId,
                title: searchString.toString().trim(),
                pager: {
                    size,
                },
            })
            .then((result) => {
                const items = result.items.map(cg => cg.getObjectFromApi());

                if (items.length) {
                    this.setLazyLoadBrands(items);
                } else {
                    this.setLazyLoadBrands([{}]);
                }
            });
    }

    /**
     * @private
     */
    prepareEntityData = () => {
        return {
            title: this.entity.title,
            groupId: this.entity.group.id,
            locationId: this.entity.location.id,
            brandId: this.entity.brand.id,
            typeId: this.entity.type.id,
            organization: this.entity.organization,
            address: {
                city: (this.entity.address.city) ? this.entity.address.city : '',
                postcode: (this.entity.address.postcode) ? this.entity.address.postcode : '',
                street: (this.entity.address.street) ? this.entity.address.street : '',
                building: (this.entity.address.building) ? this.entity.address.building : '',
                additional: (this.entity.address.additional) ? this.entity.address.additional : '',
            },
            phone: (this.entity.phone) ? this.entity.phone : '',
            email: this.entity.email,
            website: this.entity.website,
            enabled: this.entity.enabled,
            clientId: this.entity.clientId,
            titleSms: this.entity.titleSms,
        };
    }

    /**
     * @param {int} userId
     * @return {boolean}
     * @private
     */
    userLinkExists = (userId) => {
        return this
            .users
            .findIndex((item) => item.employee.id === userId && item.linked) > -1;
    };

    /**
     * @param {int} integrationId
     * @return {boolean}
     * @private
     */
    integrationLinkExists = (integrationId) => {
        return this
            .integrations
            .findIndex((item) => item.adplatform.id === integrationId && item.linked) > -1;
    };
}

const branchStore = new BranchStore();
export default branchStore;
