import { api } from "../core";
import { serialize } from "../helper";
import { Company } from "../models";
import _ from "underscore";
import uiStore from "../stores/UiStore";

class CompanyService {

    /**
     * @param {int} clientId
     * @param {{}} filter
     * @return {Promise<{items: Array<Company>, pager: PagerAnswer, sort: SortAnswer}>}
     */
    getCompanies(clientId, filter) {
        filter = _.clone(filter || {});
        filter['clientId'] = clientId;

        if (!filter.title?.length) {
            delete filter.title;
        }

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/geodata/companies?${serialize(filter)}`)
            .then(result => {
                return {
                    items: result.items.map(item => new Company(item)),
                    pager: result.pager,
                    sort: result.sorting,
                };
            });
    }

    /**
     * @param {int} id
     * @param {{}} data
     * @return {Promise<Company>}
     */
    saveEntity = (id, data) => {
        return api
            .put(`${process.env.REACT_APP_API_URL}/admin/geodata/companies/${id}`, data)
    }

    loadEntity = (id, onlyLinked = null) => {
        const result = {
            company: null,
            users: {},
            adplatforms: {},
        };

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/geodata/companies/${id}`)
            .then(company => {
                result.company = new Company(company);

                const additionalPromises = [];

                additionalPromises.push(
                    this
                        .getManagers(company.id, onlyLinked)
                        .then((links) => {
                            result.users = links;
                        }),
                );
                additionalPromises.push(
                    this
                        .getAdPlatforms(company.id, onlyLinked)
                        .then((links) => {
                            result.adplatforms = links;
                        })
                )
               return Promise.all(additionalPromises);
            })
            .then(() => {
                return result;
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                )
            });
    }

    /**
     * @param {{}} data
     * @return {Promise<Branch>}
     */
    addEntity = (data) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/geodata/companies`, data)
            .then(result => {
                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Элемент добавлен',
                        sticky: false,
                    }
                )

                return new Company(result);
            });
    }

    getManagers = (companyId, onlyLinked = null) => {
        const filter = {};

        if (onlyLinked !== null) {
            filter.onlyLinks = (onlyLinked) ? 1 : 0;
        }

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/geodata/companies/${companyId}/users?${serialize(filter)}`)
            .then((result) => {
                return {
                    items: result.items,
                    pager: result.pager,
                    linkCount: result.linkCount,
                };
            });

    }

    getAdPlatforms = (companyId, onlyLinked = null) => {
        const filter = {};

        if (onlyLinked !== null) {
            filter.onlyLinks = (onlyLinked) ? 1 : 0;
        }

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/geodata/companies/${companyId}/adplatforms?${serialize(filter)}`)
            .then((result) => {
                return {
                    items: result.items,
                    pager: result.pager,
                    linkCount: result.linkCount,
                };
            });
    }

}

const companyService = new CompanyService();
export default companyService;