import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { reaction } from "mobx";
import cn from "classnames";
import _ from "underscore";

import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import Activity from "components/common/Activity";
import TitleH1 from "components/common/TitleH1";
import { Dropdown } from "primereact/dropdown";
import 'styles/pages/branchs.scss';
/**
 * @param {UiStore} uiStore
 * @param {BreadcrumbsStore} breadcrumbsStore
 * @param {BranchStore} branchStore
 * @param {ClientStore} clientStore
 * @param {MainStore} mainStore
 * @return {JSX.Element}
 * @constructor
 */
const BranchesPage = ({ uiStore, breadcrumbsStore, branchStore, clientStore, mainStore }) => {
    const { clientId } = useParams();

    useEffect(() => {
        clientStore.loadEntity(clientId)
            .then(() => {
                mainStore.setClient(clientStore.entity)

                uiStore.setPageProperty({
                    page: 'clients',
                    className: 'branches'
                });

                breadcrumbsStore.addItem({
                    linkName: 'Клиенты',
                    link: '/clients',
                }, 0);

                breadcrumbsStore.addItem({
                    linkName: clientStore.entity.title,
                    link: `/clients/${clientStore.entity.id}/detail`,
                }, 1);

                breadcrumbsStore.addItem({
                    linkName: 'Филиалы',
                    link: `/clients/${clientStore.entity.id}/branches`,
                }, 2);
            });

    }, [])


    let history = useHistory();

    /** @type {Array<Branch>} items */
    const { title, setTitle, items, pager, setPager, resetFilter, fEnabled, setFEnabled } = branchStore;
    const [first, setFirst] = useState(0);

    useEffect(() => {
        resetFilter();
        branchStore.loadItems(clientId);
    }, []);


    useEffect(() => {
        const disposers = [
            reaction(
                () => [
                    branchStore.fEnabled,
                ],
                () => {
                    branchStore.loadItems(clientId);
                }
            ),
            reaction(
                () => branchStore.title,
                (value) => {
                    titleDebounceHandler(value)
                }
            ),
            reaction(
                () => branchStore.pager.number,
                () => {
                    branchStore.loadItems(clientId);
                }
            ),
        ];

        return () => {
            disposers.map(func => func());
        }


    }, [])

    const titleDebounceHandler = _.debounce(
        (value) => (value.length >= 2 || value.length === 0) && branchStore.loadItems(clientId),
        800
    );

    /** @namespace item.brand */
    return (
        <div>
            <div className={cn('header-container')}>
                <TitleH1
                    count={pager.itemCount}
                    ico={'house_gradient'}
                    color={'color-lilac'}
                >
                    Филиалы
                </TitleH1>
            </div>
            <div className={cn('add-container')}>
                <Button
                    className={cn('p-button-outlined')}
                    label="Создать филиал"
                    icon="pi pi-plus"
                    onClick={() => {
                        history.push(`/clients/${clientId}/branches/new`);
                    }}
                />
            </div>

            <div className={cn('search-container')}>
                <div className={cn('dropdown-container')} style={{ marginRight: '24px' }}>
                    <div className="p-field">
                        <span className="p-float-label">
                            <Dropdown
                                value={fEnabled}
                                options={[
                                    { name: 'Все', code: -1 },
                                    { name: 'Активна', code: 1 },
                                    { name: 'Не активна', code: 0 },
                                ]}
                                onChange={(e) => {
                                    setFEnabled(e.value)
                                }}
                                id="fEnabled"
                                name="fEnabled"
                                optionLabel="name"
                                optionValue="code"
                                className={'p-inputwrapper-filled'}
                            />
                                <label htmlFor="fEnabled">Активность</label>
                            </span>
                    </div>
                </div>
                <div className={cn('inputtext-container')}>
                    <div className="p-field">
                        <span className="p-input-icon-left">
                            <i className="pi pi-search"/>
                            <InputText
                                placeholder="Поиск по названию филиала..."
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />
                        </span>
                    </div>
                </div>
            </div>

            <div className={cn('table-container')}>
                <table>
                    <thead>
                    <tr>
                        <th className={cn('id-head')} colSpan={2}>ID</th>
                        <th>Название филиала</th>
                        <th>Юридическое лицо</th>
{/*
                        <th>Локация</th>
                        <th>Бренд филиала</th>
*/}
                        <th>Тип филиала</th>
                        <th>Создан</th>
                        <th>Изменен</th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map((item, key) => (
                        <tr key={key}>
                            <td>{item.id}</td>
                            <td className={cn('activity-data')}>
                                <Activity activity={item.enabled}/>
                            </td>
                            <td>
                                <Link to={`/clients/${item.clientId}/branches/${item.id}/detail`}>{item.title}</Link>
                            </td>
                            <td>{item.organization}</td>
{/*
                            <td>{item.location.title}</td>
                            <td>{item.brand.title}</td>
*/}
                            <td>{item.type.title}</td>
                            <td>{item.createdAt}</td>
                            <td>{item.updatedAt}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            <div className={cn('paginator-container')}>
                {pager.itemCount > 0 && <Paginator
                    first={first}
                    rows={pager.size}
                    totalRecords={pager.itemCount}
                    onPageChange={(e) => {
                        setFirst(e.page * pager.size);
                        setPager({
                            number: (e.page + 1),
                        })
                    }}
                />}
            </div>

        </div>
    );
};

BranchesPage.propTypes = {
    uiStore: PropTypes.any,
    breadcrumbsStore: PropTypes.any,
    branchStore: PropTypes.any,
    clientStore: PropTypes.any,
    mainStore: PropTypes.any,
}

export default inject('uiStore', 'breadcrumbsStore', 'branchStore', 'clientStore', 'mainStore')(observer(BranchesPage));
