import CommonPageStore from "./CommonPageStore";
import { Company } from "../models";
import { action, makeObservable, observable, toJS } from "mobx";
import uiStore from "./UiStore";
import { companyService, integrationService, userService } from "../services";
import { setLinkedById, unsetLinkedById } from "../helper";

class CompanyStore extends CommonPageStore {
    /**
     * @type {Array<Company>}
     */
    items = [];

    // noinspection JSValidateTypes
    /**
     * @type {Company}
     */
    entity = Company.getEmptyEntity().getPlainObject();

    /**
     * @type {BranchManagerLink[]}
     */
    users = [];

    /**
     * @type {{itemCount: number}}
     */
    usersPager = {};

    /**
     * @type {int[]}
     */
    usersUnsavedLinks = [];

    /**
     * @type {int[]}
     */
    usersUnsavedDelLinks = [];

    integrations = [];

    /**
     * @type {{itemCount: number}}
     */
    integrationsPager = {};


    integrationsUnsavedLinks = [];

    /**
     * @type {int[]}
     */
    integrationsUnsavedDelLinks = [];

    constructor() {
        super();

        makeObservable(this, {
            items: observable,
            entity: observable,
            users: observable,
            usersPager: observable,
            integrations: observable,
            integrationsPager: observable,

            setItems: action,
            setEntity: action,
            resetEntity: action,
            setUsers: action,
            setUsersPager: action,
            setIntegrations: action,
            setIntegrationsPager: action,

            saveEntity: action,
            addEntity: action,

            setUserLinked: action,
            delUserLinked: action,

            setIntegrationLinked: action,
            delIntegrationLinked: action,

        })
    }

    setUsers = (users) => {
        this.users = users;
    }

    setUsersPager = (pager) => {
        this.usersPager = pager;
    }

    setIntegrations = (integrations) => {
        this.integrations = integrations;
    }

    setIntegrationsPager = (pager) => {
        this.integrationsPager = pager;
    }

    setItems = (items) => {
        this.items = items || [];
    }

    setEntity = (entity = {}) => {
        this.entity = {
            ...this.entity,
            ...entity,
        }
    }

    resetEntity = () => {
        this.setEntity({
            id: null,
            title: '',
            enabled: false,
            type: {
                code: null
            }
        });
    }

    saveEntity = () => {

        const data = {
            title: this.entity.title,
            enabled: this.entity.enabled,
            type: this.entity.type.code,
        }

        uiStore.startLoading();


        const usersLink = [];
        const usersUnlink = [];

        const integrationsLink = [];
        const integrationsUnlink = [];

        this.usersUnsavedLinks.forEach((userId) => {
            if (!this.userLinkExists(userId)) {
                usersLink.push(userId);
            }
        });
        this.usersUnsavedDelLinks.forEach((userId) => {
            if (this.userLinkExists(userId)) {
                usersUnlink.push(userId);
            }
        });

        this.integrationsUnsavedLinks.forEach((integrationId) => {
            if (!this.integrationLinkExists(integrationId)) {
                integrationsLink.push(integrationId);
            }
        });
        this.integrationsUnsavedDelLinks.forEach((integrationId) => {
            if (this.integrationLinkExists(integrationId)) {
                integrationsUnlink.push(integrationId);
            }
        });

        const entityId = this.entity.id;

        return companyService
            .saveEntity(entityId, data)
            .then(() => {

                uiStore.setMessage(
                    {
                        severity: 'success',
                        summary: 'Успех',
                        detail: 'Изменения сохранены',
                        sticky: false,
                    }
                );

                const linkPromises = [
                    ...usersLink.map(userId => userService.setLinkedByCompany(userId, entityId)),
                    ...usersUnlink.map(userId => userService.delLinkedByCompany(userId, entityId)),
                    ...integrationsLink.map(integrationId => integrationService.setLinkedByCompany(integrationId, entityId)),
                    ...integrationsUnlink.map(integrationId => integrationService.delLinkedByCompany(integrationId, entityId)),
                ];

                this.usersUnsavedLinks = [];
                this.usersUnsavedDelLinks = [];

                this.integrationsUnsavedLinks = [];
                this.integrationsUnsavedDelLinks = [];

                if (linkPromises.length) {
                    return Promise.all(linkPromises);
                }

                return Promise.resolve();
            })
            .catch((error) => {
                error.error.data.map(item => {
                    uiStore.setMessage(
                        {
                            severity: 'error',
                            summary: error.error.description,
                            detail: item.message,
                        }
                    )
                });

                return Promise.reject();
            })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    loadItems = (clientId) => {
        let filter = {
            page: toJS(this.pager),
            sort: toJS(this.sort),
            title: toJS(this.title),
        };

        if (this.fEnabled !== -1) {
            filter = {
                ...filter,
                ...{ enabled: toJS(this.fEnabled) }
            }
        }

        uiStore.startLoading();

        companyService.getCompanies(clientId, filter).then((result)=>{
            this.setItems(result?.items);
            this.setSort(result?.sort);
            this.setPager(result?.pager);
        })
            .finally(() => {
                uiStore.stopLoading();
            });
    }

    loadEntity = (id, isEditPage = false) => {
        uiStore.startLoading();

        this.usersUnsavedLinks = [];
        this.usersUnsavedDelLinks = [];

        this.integrationsUnsavedLinks = [];
        this.integrationsUnsavedDelLinks = [];

        this.setIntegrations([]);
        this.setUsers([]);

        companyService
            .loadEntity(id, isEditPage ? null : true)
            .then(result => {
                this.setEntity(result.company.getPlainObject());

                this.setUsers(result.users.items);
                this.setUsersPager(result.users.pager);

                this.setIntegrations(result.adplatforms.items);
                this.setIntegrationsPager(result.adplatforms.pager);

            })
            .catch((error) => {
                uiStore.setMessage({
                    severity: 'error',
                    summary: error.response?.data.error.type,
                    detail: error.response?.data.error.description,
                });
            })
            .finally(() => {
                uiStore.stopLoading();
            });

    }


    addEntity = () => {
        const data = {
            clientId: this.entity.clientId,
            title: this.entity.title,
            enabled: this.entity.enabled,
            type: toJS(this.entity.type.code),
        }



        uiStore.startLoading();

        return companyService
            .addEntity(data)
            .then(result => {
                this.setEntity(
                    result.getPlainObject()
                );
            })
            .finally(() => {
                uiStore.stopLoading();
            });

    }

    /**
     * @private
     */
    prepareEntityData = () => {
        return {
            title: this.entity.title,
            enabled: this.entity.enabled,
        };
    }

    setUserLinked = (userId) => {
        this.usersUnsavedDelLinks = setLinkedById(userId, this.usersUnsavedLinks, this.usersUnsavedDelLinks);
    }

    delUserLinked = (userId) => {
        this.usersUnsavedLinks = unsetLinkedById(userId, this.usersUnsavedLinks, this.usersUnsavedDelLinks);
    }

    setIntegrationLinked = (integrationId) => {
        this.integrationsUnsavedDelLinks = setLinkedById(
            integrationId,
            this.integrationsUnsavedLinks,
            this.integrationsUnsavedDelLinks,
        );
    }

    delIntegrationLinked = (integrationId) => {
        this.integrationsUnsavedLinks = unsetLinkedById(
            integrationId,
            this.integrationsUnsavedLinks,
            this.integrationsUnsavedDelLinks,
        );
    }

    /**
     * @param {int} userId
     * @return {boolean}
     * @private
     */
    userLinkExists = (userId) => {
        return this
            .users
            .findIndex((item) => item.employee.id === userId && item.linked) > -1;
    };

    /**
     * @param {int} integrationId
     * @return {boolean}
     * @private
     */
    integrationLinkExists = (integrationId) => {
        return this
            .integrations
            .findIndex((item) => item.adplatform.id === integrationId && item.linked) > -1;
    };


}


const companyStore = new CompanyStore();
export default companyStore;
