import { api } from "../core";
import uiStore from "../stores/UiStore";
import { AdPlatform, BranchAdPlatformLink } from "../models";
import { serialize } from "helper";
import { AdPlatformConnectionStatus } from "models/AdPlatformConnectionStatus";
import AdPlatformPublication from "models/AdPlatformPublication";
import AdPlatformReview from "models/AdPlatformReview";
import AdPlatformParser from "models/AdPlatformParser";

class IntegrationService {


    getParserAccountList() {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/adparser/accounts`)
            .then((result) => {
                return {
                    items: result.items,
                };
            })
            .catch((error) => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                );
                return Promise.reject();
            });

    }

    /**
     * @param {{}} filter
     * @return {Promise<{items: AdPlatform[], pager: PagerAnswer, sort: SortAnswer}>}
     */
    getIntegrations(filter) {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms?${serialize(filter)}`)
            .then((result) => {
//TODO после внедрения разных статусов для интеграций, написать еще одну модель для элементов списка интеграций
                return {
                    //items: result.items.map(item => new AdPlatform(item)),
                    items: result.items,
                    pager: result.pager,
                    sort: result.sorting,
                };
            })
            .catch((error) => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                );

                return Promise.reject();
            });
    }

    /**
     * @param {int} integrationId
     * @param {int} branchId branch ID
     * @return {Promise}
     */
    setLinked = (integrationId, branchId) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/branches/${branchId}`, {})
            .then(() => { // POST /admin/integration/adplatforms/637/branches/206
                console.debug(`Linked integrationId: ${integrationId}, branchId: ${branchId}`);
                return Promise.resolve(true);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                );

                return Promise.reject();
            });
    }

    /**
     * @param {int} integrationId
     * @param {int} branchId branch ID
     * @return {Promise}
     */
    delLinked = (integrationId, branchId) => {
        return api
            .delete(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/branches/${branchId}`)
            .then(() => {
                console.debug(`Unlinked integrationId: ${integrationId}, branchId: ${branchId}`);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                );

                return Promise.reject();
            });
    }

    /**
     * @param {int} integrationId
     * @param {int} companyId company ID
     * @return {Promise}
     */
    setLinkedByCompany = (integrationId, companyId) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/geodata/companies/${companyId}/adplatforms/${integrationId}`, {})
            .then(() => { // POST /admin/integration/adplatforms/637/branches/206
                console.debug(`Linked integrationId: ${integrationId}, companyId: ${companyId}`);
                return Promise.resolve(true);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                );

                return Promise.reject();
            });
    }

    /**
     * @param {int} integrationId
     * @param {int} companyId company ID
     * @return {Promise}
     */
    delLinkedByCompany = (integrationId, companyId) => {
        return api
            .delete(`${process.env.REACT_APP_API_URL}/admin/geodata/companies/${companyId}/adplatforms/${integrationId}`)
            .then(() => {
                console.debug(`Unlinked integrationId: ${integrationId}, companyId: ${companyId}`);
            })
            .catch(error => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                );

                return Promise.reject();
            });
    }


    /**
     * @param {int} integrationId
     * @param {boolean|null} onlyLinked
     * @return {Promise<{adPlatformId: int, items: BranchAdPlatformLink[], linkCount: int, pager: PagerAnswer}>}
     */
    findBranches = (integrationId, onlyLinked = null) => {
        const filter = {};

        if (onlyLinked !== null) {
            filter.onlyLinks = (onlyLinked) ? 1 : 0;
        }

        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/branches?${serialize(filter)}`)
            .then((result) => {
                return {
                    adPlatformId: integrationId,
                    items: result.items.map((link) => new BranchAdPlatformLink(link)),
                    linkCount: result.linkCount,
                    pager: result.pager,
                }
            });
    }

    /**
     * @param {int} integrationId
     * @return {Promise<{adPlatform: AdPlatform, connectionStatus: AdPlatformConnectionStatus}>}
     */
    getIntegration = (integrationId) => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}`)
            .then((result) => {
                return {
                    adPlatform: new AdPlatform(result)
                }
            })
            .catch((error) => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                );

                return Promise.reject();
            });
    }



    /**
     * @param {int} integrationId
     * @param {{}} data
     * @return {Promise<T>}
     */
    save = (integrationId, data) => {
        return api
            .put(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}`, data)
            .then((result) => new AdPlatform(result))
            .catch((error) => {
                uiStore.setMessage(
                    {
                        severity: 'error',
                        summary: error.response?.data.error.type,
                        detail: error.response?.data.error.description,
                    }
                );

                return Promise.reject();
            });
    }

    /**
     * @param {{}} data
     * @return {Promise<{adPlatform: AdPlatform, connectionStatus: connectionStatus: AdPlatformConnectionStatus}>}
     */
    add = (data) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms`, data)
            .then((result) => this.getIntegration(result.id))
    }


    getParser = (integrationId) => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/connections/parser`, () => {
                return Promise.resolve({});
            })
            .then((result) => {
                return new AdPlatformParser(result).getPlainObject();
            })
            .catch(error => {
                return Promise.reject(error.response.data);
            });
    }

    activateParser = (integrationId) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/connections/parser/activate`, {})
            .then((result) => result.id)

    }

    deactivateParser = (integrationId) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/connections/parser/deactivate`, {})
            .then((result) => result.id)

    }

    addParser = (integrationId, data) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/connections/parser`, data)
            .then((result) => result.id)

    }

    editParser = (integrationId, data) => {
        return api
            .put(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/connections/parser`, data)
            .then((result) => result.id)
    }

    getCollector = (integrationId) => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/connections/collector`, () => {
                return Promise.resolve({});
            })
            .then((result) => {
                return new AdPlatformReview(result).getPlainObject();
            });
    }

    addCollector = (integrationId, data) => {

        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/connections/collector`, data)
            .then((result) => result.id)

    }

    editCollector = (integrationId, data) => {
        return api
            .put(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/connections/collector`, data)
            .then((result) => result.id)

    }

    getPublisher = (integrationId) => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/connections/publisher`, () => {
                return Promise.resolve({});
            })
            .then((result) => {
                return new AdPlatformPublication(result).getPlainObject();
            });
    }

    addPublisher = (integrationId, data) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/connections/publisher`, data)
            .then((result) => result.id)
    }

    editPublisher = (integrationId, data) => {
        return api
            .put(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/connections/publisher`, data)
            .then((result) => result.id)
    }

    /**
     * Получение данных о подключенном парсинге
     *
     * @param {int} integrationId
     * @return {Promise<AdPlatformConnectionStatus>}
     */
    getConnectionStatus = (integrationId) => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/connections/parser`,)
            .then((result) => new AdPlatformConnectionStatus(result))
            .catch(error=>error);
    };

    /**
     * Принудительное обновление данных о подключенном парсинге
     *
     * @param {int} integrationId
     * @return {Promise<AdPlatformConnectionStatus>}
     */
    refreshConnectionStatus = (integrationId) => {
        return api
            .get(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/connections/parser/refresh-status`)
            .then((result) => new AdPlatformConnectionStatus(result));
    };

    /**
     * Активация подключения к парсеру (создание)
     *
     * @param {int} integrationId
     * @return {Promise<boolean>}
     */
    makeConnection = (integrationId) => {
        return api
            .post(`${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}connections/parser`)
            .then((result) => {
                return result.id > 0;
            });
    };

    /**
     * Включение парсинга (активация)
     *
     * @param {int} integrationId
     * @returns {Promise<boolean>}
     */
    activateConnection = (integrationId) => {
        return api
            .post(
                `${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/connections/parser/activate`
            )
            .then((result) => {
                return result === 'OK';
            });
    };

    /**
     * Отключение парсинга (деактивация)
     *
     * @param {int} integrationId
     * @returns {Promise<boolean>}
     */
    deactivateConnection = (integrationId) => {
        return api
            .post(
                `${process.env.REACT_APP_API_URL}/admin/integration/adplatforms/${integrationId}/connections/parser/deactivate`
            )
            .then((result) => {
                return result === 'OK';
            });
    };
}

const integrationService = new IntegrationService();
export default integrationService;
